import _objectSpread from "/Users/yuqingying/Documents/ranJing/project/frontend/school-sport-test-h5-student/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import "core-js/modules/es.function.name.js";
import { defineComponent, reactive, toRefs } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from '@/store';
import { navBar } from '@/constant/constants';
import cVanNavBar from '@/components/cVanNavBar.vue';
import { isEmpty } from '@/utils/func';
export default defineComponent({
  props: {},
  components: {
    cVanNavBar: cVanNavBar
  },
  setup: function setup() {
    var router = useRouter();
    var route = useRoute();
    var store = useStore();
    var dataMap = reactive({
      ruleForm: {
        name: '',
        sex: '1',
        number: '',
        grade: '',
        clazz: '',
        groupTitle: '',
        id: 1
      },
      navBar: {
        title: '学生基础信息',
        color: navBar.BLACK
      },
      hasAvatar: false,
      avatarUrl: ''
    });
    var token = route.params.token;
    var name = route.params.name;
    var number = route.params.number;
    store.dispatch('login/accountLoginAction', {
      username: name,
      number: number,
      token: token
    }).then(function (res) {
      if (res.code == 200) {
        if (!isEmpty(res.data.name) && !isEmpty(res.data.number)) {
          dataMap.ruleForm.name = res.data.name;
          dataMap.ruleForm.sex = res.data.sex;
          dataMap.ruleForm.number = res.data.number;
          dataMap.ruleForm.grade = res.data.grade;
          dataMap.ruleForm.clazz = res.data.clazz;
          dataMap.ruleForm.groupTitle = res.data.group_title;
          dataMap.ruleForm.id = res.data.id;

          if (!isEmpty(res.data.avatar)) {
            dataMap.hasAvatar = true;
            dataMap.avatarUrl = res.data.avatar;
          }
        } else {
          _Notify({
            type: 'danger',
            message: '操作异常'
          });
        }
      }
    });

    function handleGoBack() {
      router.push('/login?token=' + token);
    }

    function handleGotoCameraFace() {
      router.push('/cameraFace/' + token + '/' + dataMap.ruleForm.number + '/' + dataMap.ruleForm.name);
    }

    var refData = toRefs(dataMap);
    return _objectSpread(_objectSpread({}, refData), {}, {
      handleGoBack: handleGoBack,
      handleGotoCameraFace: handleGotoCameraFace
    });
  }
});